$size: 960px;

nav {
    padding: 0 15px;
}

nav a {
    padding: 0 15px;
}

header {
    font-size: 18pt;
    position: fixed;
    top: 0;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    font-size: 14pt;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

    .shop-title {
        padding: 20px 0 20px 30px;
    }

    button {
        padding: 15px 30px;
    }
}

@media screen and (max-width: $size) {
    nav {
        padding: 0;
        a {
            padding: 0 15px;
        }
    }
}