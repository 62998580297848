.forgotten-passowrd {
    display: flex;
    flex-direction: column;
    margin: 100px auto 20px auto;
    width: 450px;
    padding: 48px 40px 36px;
    box-sizing: border-box;
    border: 1px solid #dadce0;
    -webkit-border-radius: 8px;
    border-radius: 8px;
  }
  
  .forgotten-passowrd h1 {
    margin: 0px 0 -0.125rem;
    padding: 0 0 35px 0;
    color: #fff;
    text-align: center;
    color: #202124;
    font-family: 'Google Sans','Noto Sans Myanmar UI',arial,sans-serif;
    font-size: 24px;
    font-weight: 400;
  }
  
  .forgotten-passowrd .inputBox {
    display: flex;
    flex-direction: column;
  }
  
  .forgotten-passowrd .inputBox input {
    color: #202124;
    font-size: 16px;
    height: 28px;
    margin: 1px 1px 0 1px;
    padding: 13px 15px;
    margin: 10px 0;
    border: 1px solid #ccc;
    background: transparent;
    -webkit-border-radius: 4px;
    border-radius: 4px;
  }
  
  .forgotten-passowrd .inputBox label {
    font-size: 1rem;
    width: fit-content;
    padding: 0 8px;
    color: grey;
    background-color: white;
    pointer-events: none;
    transform: translate(10px, 20px);
    -webkit-border-radius: 4px;
    border-radius: 4px;
  }

  .forgotten-passowrd-change {
    margin-top: 30px;
    color: #287ae6;
    text-align: center;
  }