$size: 960px;
$font-colour: #333333;
$nav-colour: #ffffff;

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

html,
body,
#root {
    margin: 0px;
    // height: 100%;
    font-family: 'Roboto', sans-serif;
    // font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
    color: $font-colour;
}

header {
    display: flex;
    flex-direction: row;
    background-color: $nav-colour;
    color: $font-colour;
    align-items: center;
    width: 100%;
}

main {
    margin: 100px auto 10px auto;
}

nav {
    display: flex;
    align-items: center;
    ul {
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        li {
            padding: 20px 30px;
            display: flex;
            height: 100%;
            align-items: center;
        }
        li:hover {
            background-color: rgba(106,165,169, 0.1);
        }
    }
}

h1 {
    margin: 0;
}

h2 {
    font-size: 12pt;
    padding: 10px;
}

@media screen and (max-width: $size) {
    header .shop-title {
        padding: 15px 15px;
    }
}