$size: 960px;

.welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  height: 100vh;
  margin: 0 auto;
  h1 {
    height: 60px;
    font-size: 65px;
  }

  .sign-in-extras {
    display: flex;
    flex-direction: column;
    font-size: 28px;
    color: #303030;
    padding: 10px;
    a, button {
      padding: 3px;
      &:hover {
        font-weight: bold;
        color: #1d5bad;
      }
    }
    button {
      text-align: left;
    }
  }
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-in {
    display: flex;
    flex-direction: column;
    margin: 100px auto 20px auto;
    width: 450px;
    padding: 48px 40px 36px;
    box-sizing: border-box;
    border: 1px solid #dadce0;
    -webkit-border-radius: 8px;
    border-radius: 8px;

    h1 {
      margin: 0px 0 -0.125rem;
      padding: 0 0 35px 0;
      color: #fff;
      text-align: center;
      color: #202124;
      font-family: 'Google Sans','Noto Sans Myanmar UI',arial,sans-serif;
      font-size: 24px;
      font-weight: 400;
    }

    .button {
      margin: 30px 0 5px 0;
    }

    .inputBox {
      display: flex;
      flex-direction: column;

      input {
        color: #202124;
        font-size: 16px;
        height: 28px;
        margin: 1px 1px 0 1px;
        padding: 13px 15px;
        margin: 10px 0;
        border: 1px solid #ccc;
        background: transparent;
        -webkit-border-radius: 4px;
        border-radius: 4px;
      }

      label {
        font-size: 1rem;
        width: fit-content;
        padding: 0 8px;
        color: grey;
        background-color: white;
        pointer-events: none;
        transform: translate(10px, 20px);
        -webkit-border-radius: 4px;
        border-radius: 4px;
      }

      .language-label {
        font-size: 1rem;
        width: fit-content;
        padding: 0 8px;
        color: grey;
        margin: 20px 0 10px 0;
      }
    }
  }

  .sign-in-change {
    margin-top: 30px;
    color: #287ae6;
  }

  .forgotten-passowrd-text {
    font-size: 11pt;
    color: #287ae6;
  }

  .error {
    height: 35px;
    font-size: 14px;
    padding: 5px 20px;
    background: #FFCCCC;
    color: #c62828;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      height: 20px;
      padding-right: 10px;
    }
  }

  .hide-error {
    height: 40px;
  }

  @media screen and (max-width: $size) {
    .sign-in {
        width: 90%;
    }

    .welcome {
      h1 {
        min-height: 120px;
        font-size: 50px;
        height: unset;
      }
    }
  }