$size: 960px;

.subscribed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px auto 20px auto;
    max-width: 750px;
    padding: 20px;
    box-sizing: border-box;
    border: 1px solid #dadce0;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    img {
        height: 125px;
        padding: 20px;
    }
    h1 {
        max-width: 430px;
        text-align: center;
        padding: 20px;
    }
    p {
        max-width: 550px;
        text-align: center;
    }
}

@media screen and (max-width: $size) {
  .subscribed {
      width: 90%;
  }
}