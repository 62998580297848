$size: 960px;

.account-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    margin: 100px auto 20px auto;
    padding: 40px;
    width: 550px;

    box-sizing: border-box;
    border: 1px solid #dadce0;
    -webkit-border-radius: 8px;
    border-radius: 8px;

    .language-box {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        
        margin: 20px auto 0px auto;
        // padding: 40px;
        // width: 550px;
    
        // box-sizing: border-box;
        // border: 1px solid #dadce0;
        // -webkit-border-radius: 8px;
        // border-radius: 8px;
    
        .button {
          margin: 0 20px 0 10px;
          &.disable {
            pointer-events: none;
            cursor: not-allowed;
            background: rgba(#969696, 0.3);
          }
        }
        
        h1 {
          margin: 0px 0 -0.125rem;
          padding: 0 0 35px 0;
          color: #fff;
          text-align: center;
          color: #202124;
          font-family: 'Google Sans','Noto Sans Myanmar UI',arial,sans-serif;
          font-size: 24px;
          font-weight: 400;
        }
    }
}
  
.account-box h1 {
  margin: 0px 0 -0.125rem;
  padding: 0 0 35px 0;
  color: #fff;
  text-align: center;
  color: #202124;
  font-family: 'Google Sans','Noto Sans Myanmar UI',arial,sans-serif;
  font-size: 24px;
  font-weight: 400;
}

.account-box .record {
  display: flex;
  flex-direction: column;
}

.account-box .record input {
  color: #202124;
  font-size: 16px;
  height: 28px;
  margin: 1px 1px 0 1px;
  padding: 13px 15px;
  margin: 10px 0;
  border: 1px solid #ccc;
  background: transparent;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.account-box .record label {
  font-size: 1rem;
  width: fit-content;
  padding: 0 8px;
  color: grey;
  background-color: white;
  pointer-events: none;
  transform: translate(10px, 20px);
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.account-box .button {
  margin: 40px 0 5px 0;
}

@media screen and (max-width: $size) {
  .account-box {
    margin: 150px 5% 30px 5%;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 90%;
    height: fit-content;
  }
}