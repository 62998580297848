$size: 960px;

.gpt-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px auto 20px auto;
    width: fit-content;
    
    .back-button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
    }
}

.include-language {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 100px 0 0 0;
}

.language-gpt-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    .button {
        margin-left: 20px;
        
    }
}

.gpt-options {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1000px;
    margin-bottom: 20px;
}

.gpt-options .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    max-width: 220px;
    height: 300px;

    border: none;
    outline: none;
    color: #fff;
    background-color: #3e87e6;
    padding: 10px 20px;
    margin: 5px 10px 5px 10px;
    cursor: pointer;
    -webkit-border-radius: 4px;
    border-radius: 4px;

    &:hover {
        background-color: #287ae6;
        box-shadow: 0 1px 1px 0 rgba(66,133,244,0.45), 0 1px 3px 1px rgba(66,133,244,0.3);
    }

    &.coming-soon {
        background-color: rgba(#999999, 0.5);
        cursor: default;
        
        &:hover {
            background-color: rgba(#999999, 0.5);
            box-shadow: unset;
        }
    }
}

.gpt-options .card h1 {
    padding: 25px 0;
    text-align: center;
}

.gpt-options .card span {
    padding: 0 0 25px 0;
    text-align: center;
}

textarea {
    font-size: 16px;
    padding: 5px;
}

.gpt-reply {
    align-items: center;
    justify-content: center;

    width: 750px;
    min-height: 400px;
    padding: 10px;

    box-sizing: border-box;
    border: 1px solid #dadce0;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    .hide {
        display: none;
    }
    .maths { 
        font-size: 14pt;
        padding: 5px;
    }
}

.gpt-input {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 5px;
    width: 750px;

    box-sizing: border-box;
    border: 1px solid #dadce0;
    -webkit-border-radius: 8px;
    border-radius: 8px;
}

.gpt-box .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .raw {
        font-size: 10pt;
        color: #1a73e8;
        padding: 5px;
        height: fit-content;

        &.hide {
            display: none;
        }
    }

    &.hide {
        justify-content: flex-end;
    }
}

.back-button .trial {
    font-size: 1rem;
    border: 1px solid #1a73e8;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    padding: 10px 20px;
    margin: 5px 0;
}

@media screen and (max-width: $size) {
    .include-language {
        margin: 80px 0 0 0;
    }

    .gpt-options {
        flex-direction: column;
        
        .card {
            height: unset;
        }
    }

    .gpt-box {
        width: 100%;
        margin: 80px 0 30px 0;

        .back-button {
            margin-bottom: 10px;
        }

        .back-button, .warning, .gpt-reply, .gpt-input, .buttons {
            width: 90%;
        }
    }

    .language-gpt-buttons {
        .language-select img {
            margin: 5px 0px 5px 20px;
        }
        .button {
            margin-left: 30px;
        }
    }
}