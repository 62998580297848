$size: 960px;

.language {
    text-align: center;
}

.landing-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .pair {
        height: 600px;
        padding: 100px;
        display: flex;
        flex-direction: row;
    }
    .between {
        padding: 50px;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1 {
            text-align: center;
            color: #055C9D;
            padding-bottom: 50px;
            font-size: 30pt;
        }
        .paragraph {
            text-align: left;
            padding-bottom: 30px;
        }
        .paragraph2 {
            text-align: center;
            padding-bottom: 30px;
        }
        .button {
            margin: 20px 0;
            text-align: center;
            &.second-button {
                background-color: rgba(#808080, 0.5);
            }
        }
    }
    .between-img {
        object-fit: cover;
        border-radius: 25px;
        max-width: 50%;
    }
    .full-img {
        object-fit: contain;
        border-radius: 25px;
        max-width: 65%;
    }
    .end-img {
        max-width: 100%;
    }
    .contact {
        text-align: center;
        padding: 50px;
    }
}

.benefits {
    .benefit-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .benefit-item {
            width: 48%;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            padding-bottom: 40px;
            img {
                height: 40px;
            }
            h2 {
                color: #287ae6;
                font-size: 14pt;
                margin: 0;
                padding: 5px 0;
            }
            div {
                text-align: left;
            }
        }
    }
}
.outro {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0px;
    max-width: 800px;
    text-align: left;
    .button {
        padding: 15px 50px;
        margin-top: 20px;
    }
}

.alternate-background {
    background: rgba(#68BBE3, 0.1);
    padding: -5%;
}

@media screen and (max-width: $size) {
    .landing-page {
        width: 100%;
    }
    .landing-page .pair {
        height: unset;
        padding: 10px 0;
        flex-direction: column;
        align-items: center;
        padding: 5%;
        max-width: 100%;
    }
    .landing-page .between {
        width: 100%;
        width: 90%;
    }
    .landing-page .between-img {
        object-fit: cover;
        border-radius: 2px;
        max-width: 90%;
    }
    .landing-page .full-img {
        object-fit: contain;
        border-radius: 5px;
        max-width: 100%;
    }
    .landing-page .pair.mobile-flip {
        flex-direction: column-reverse;
    }
    .outro {
        padding: 30px 20px;
    }
}