$size: 960px;

body.active-popup {
    overflow-y: hidden;
}

.btn-popup {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.popup {
    display: flex;
    z-index: 8;
    justify-content: center;
    align-items: center;
}

.popup, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    h2 {
        text-align: center;
    }
    .warning {
        width: unset;
        font-weight: bold;
    }
    .button {
        margin: 20px 0;
    }
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.popup-content {
    z-index: 9;
    line-height: 1.4;
    background: #f1f1f1;
    border-radius: 3px;
    width: 400px;
    padding: 14px 28px;
}

.highlight {
    color: #287ae6;
}

.smaller-icon {
    height: 30px;
}

.example-text {
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    // border: 1px solid #000000;
    border-radius: 8px;
    background-color: rgba(#1a73e8, 0.05);
    font-weight: bold;
}

@media screen and (max-width: $size) {
    .popup {
        z-index: 9;
    }
    .popup-content {
        top: 50%;
        width: 90%;
        h2 {
            padding: 0;
        }
    }
}