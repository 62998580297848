$size: 960px;

.pricing-backgroud {
    background: #F4F4F4;
    &.about-us {
        padding: 20px 0 40px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .button {
            margin: 20px 0 20px 0;
        }
    }
}

.pricing {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto 0 auto;
    padding: 30px 10px 30px 10px;
    max-width: 1000px;
    .pricing-heading {
        font-size: 52px;
        color: #303030;
        margin-bottom: 20px;
    }
    .pricing-heading-2 {
        font-size: 28px;
        color: #808080;
        margin: 0;
        padding: 0;
        font-weight: unset;
        margin-bottom: 30px;
    }
    .button {
        width: fit-content;
        font-weight: bold;
        padding: 15px 30px;
        line-height: 20px;
        margin-bottom: 10px;
    }
}

.pricing-more-info {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 0 auto 0 auto;
    padding: 50px 0 40px 0;
    max-width: 1000px;
    .pricing-split {
        padding: 10px;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
        .between-img {
            object-fit: cover;
            border-radius: 25px;
            max-width: 100%;
        }
    }
    .more-info-heading {
        font-size: 28px;
        color: #808080;
        margin-bottom: 20px;
    }
    .more-info-heading-2 {
        font-weight: unset;
        font-size: 20px;
        color: #808080;
        margin-bottom: 30px;
        padding: 0;
        div {
            padding: 5px;
        }
    }
    .button {
        // margin: 35px auto 0 auto;
        margin-top: 35px;
        padding: 15px 20px;
        font-weight: bold;
    }
}

.price-selection {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
}

.payment-box {
    display: flex;
    flex-direction: column;
    width: 250px;
    padding: 30px 20px 25px 20px;
    margin: 40px;
    border-radius: 8px;
    background-color: #1a73e8;
    color: white;
    &.free {
        background-color: rgba(#808080, 0.6);
    }
    .line {
        margin: 5px 0;
        border-top: 1px solid rgba(255, 255, 255, 0.30);
    }
    .muted-text {
        color: rgba(255, 255, 255, 0.72);
        font-size: 14px;
        b {
            color: white;
            font-size: 16px;
        }
    }
    .payment-benefits {
        list-style-type: disc;
        margin: 10px 0px 10px 20px;
        li {
            padding: 5px 0;
        }
    }
    .payment-price {
        font-size: 26px;
        // color: #3e87e6;
        color: white;
        font-weight: bolder;
        text-align: center;
    }
    .button {
      margin: 40px 0 20px 0;
      padding: 15px 20px;
      background-color: white;
      color: black;
      font-weight: bold;
    }
}

.tick-list {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #808080;
    flex-wrap: wrap;
    
    img {
        width: 20px;
        margin-right: 5px;
        opacity: 0.8;
    }

    span {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 50px;
        margin-bottom: 5px;
    }
}

@media screen and (max-width: $size) {
    .payment-box {
        width: 90%;
        max-width: 235px;
        margin: 20px auto 20px auto;
        padding: 15px;
    }

    .price-selection {
        flex-direction: column;
        padding: 10px;
    }

    .pricing .button {
        width: unset;
    }

    .pricing {
        .pricing-heading {
            font-size: 40px;
        }
        .pricing-heading-2 {
            font-size: 16px;
            div {
                margin-bottom: 2px;
            }
        }
    }

    .pricing-more-info {
        flex-direction: column;
        .pricing-split {
            width: 90%;
            align-items: flex-start;
            text-align: left;
        }
        .button {
            width: inherit;
        }
    }

    .tick-list {
        justify-content: flex-start;
    }
}