$size: 960px;

.book-details {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .book-property {
        display: flex;
        flex-direction: column;
        margin-right: 10px;

        .book-label {
            font-size: 1rem;
            width: fit-content;
            padding: 0 8px;
            color: grey;
            background-color: #F8F8F8;
            pointer-events: none;
            transform: translate(10px, 20px);
            -webkit-border-radius: 4px;
            border-radius: 4px;
        }
    
        .book-input {
            color: #202124;
            font-size: 16px;
            height: 28px;
            margin: 1px 1px 0 1px;
            padding: 13px 15px;
            margin: 10px 0;
            border: 1px solid #ccc;
            background: transparent;
            -webkit-border-radius: 4px;
            border-radius: 4px;
            width: 200px;
        }

    }
}

.summary-types {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: fit-content;
    background-color: #EAF1FD;
    border-radius: 5px;
    padding: 5px;

    .button {
        margin: 10px 10px;
        background-color: rgba(#808080, 0.4);
        &.small, &.medium, &.large {
            background-color: rgba(#1a73e8, 0.8);
        }

        &:hover {
            background-color: rgba(#808080, 0.4);
            &.small, &.medium, &.large {
                background-color: rgba(#1a73e8, 0.8);
            }
            box-shadow: unset;
        }
    }
}

.book-gpt-buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    // justify-content: space-between;
    justify-content: flex-end;
    width: 100%;
    padding-top: 5px;
}

.chapter {
    padding: 0;
    margin: 0;
    font-size: 24px;
    color: #808080;
}
.summary {
    margin: 0 0 20px 0;
}

.book-info {
    font-size: 13px;
    color: #808080;
}

@media screen and (max-width: $size) {
    .summary-types {
        width: auto;
        justify-content: center;
    }
    .book-details {
        flex-direction: column;
    }
}