$size: 960px;

.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform: -webkit-translate(-50%, -50%);
    transform: -moz-translate(-50%, -50%);
    transform: -ms-translate(-50%, -50%);

    &.loader {
        width: 70px;
        height: 70px;
        border: 5px solid #287ae6;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
        }
    
        @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

.button {
    font-size: 1rem;
    border: none;
    outline: none;
    color: #fff;
    background-color: #1a73e8;
    padding: 10px 20px;
    margin: 5px 0;
    cursor: pointer;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    text-align: center;

    &.red {
        background-color: rgba(#c62828, 0.7);
        font-weight: bold;

        &:hover {
            background-color: rgba(#c62828, 0.9);
            box-shadow: 0 1px 1px 0 rgba(#c62828, 0.5), 0 1px 3px 1px rgba(#c62828, 0.5);
        }
    }

    &:hover {
        background-color: #287ae6;
        box-shadow: 0 1px 1px 0 rgba(66,133,244,0.45), 0 1px 3px 1px rgba(66,133,244,0.3);
    }

    &.fixed-size {
        width: 110px;
    }
}

.button-loader {
    width: 20px;
    height: 20px;
    border: 5px solid #FFF;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.warning {
    margin-bottom: 10px;
    font-size: 14px;
    padding: 10px 20px;
    width: 710px;
    background: rgba(#ffba00, 0.1);
    color: #ffba00;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
        height: 40px;
        padding-right: 10px;
    }

    .mailto {
        color: #1a73e8;
        cursor: pointer;
    }
}

.language-select {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding-right: 20px;
    img {
        padding: 0 10px;
        margin: 5px 10px;
    }
    .selected {
        background-color: rgba(#1a73e8, 0.2);
        border-radius: 5px;
    }
}

.avoid-header {
    margin-top: 75px;
}

.text-center {
    text-align: center;
}

.interact-area {
    width: 100%;
    margin: 10px;
    background-color: #F8F8F8;
    padding: 10px;
    border-radius: 5px;
    width: 730px;
}

@media screen and (max-width: $size) {
    .gpt-box .interact-area {
        width: 90%;
        .buttons {
            width: 100%;
        }
    }
}